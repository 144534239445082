// React
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

// Components
import config from '../utils/config';
import LoadingSpinner from '../components/LoadingSpinner';
import logError from '../utils/errorUtil';
import SimpleLogo from '../assets/images/simpleLogo.jpg';
import Notification from '../components/Notification';

export default function CmmIpcDocument() {
    const documentGroups = {
        AIR_CERT: 'Air-Cert',
        PAC_AIR: 'Pac-Air',
    };
    const defaultDocumentData = {
        documents: [],
        loading: true,
        error: false,
    };
    const ORIGIN = config.origin;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObject = {};
    queryParams.forEach((value, key) => {
        queryParamsObject[key] = value;
    });

    const [selectedDocumentGroup, setSelectedDocumentGroup] = useState(documentGroups.PAC_AIR);
    const [activeDocument, setActiveDocument] = useState(null);
    const [airCertData, setAirCertDocuments] = useState({
        ...defaultDocumentData,
    });
    const [pacAirData, setPacAirDocuments] = useState({
        ...defaultDocumentData,
    });

    const [loadingDocument, setLoadingDocument] = useState(false);
    const [showSavedNotification, setShowSavedNotification] = useState({
        show: false,
        title: '',
        desc: '',
        status: '',
    });

    const token = queryParamsObject?.fahPAIsfo;

    const groupDocumentData = [
        {
            name: documentGroups.PAC_AIR,
            documentData: pacAirData,
            setDocumentData: setPacAirDocuments,
            route: `${ORIGIN}/document/cmmipc?fahPAIsfo=${token}`,
            isPrimary: true,
        },
        {
            name: documentGroups.AIR_CERT,
            documentData: airCertData,
            setDocumentData: setAirCertDocuments,
            route: `${ORIGIN}/wms/api/cmm-ipc/related-cmm-ipc?fahPAIsfo=${token}`,
            isPrimary: false,
        },
    ];

    const addPdfDataToDocument = (document, selectedGroupName, data) => {
        const currentDocumentGroup = groupDocumentData.find((group) => group.name === selectedGroupName);
        const updatedDocuments = currentDocumentGroup?.documentData?.documents?.map((doc) => {
            if (doc.documentId === document.documentId) {
                return { ...doc, pdf: data?.pdf };
            }
            return doc;
        });

        currentDocumentGroup.setDocumentData({ ...currentDocumentGroup.documentData, documents: updatedDocuments });
    };

    const setActive = async (item, selectedGroupName) => {
        try {
            if (loadingDocument) return;
            if (item.pdf) {
                setActiveDocument(item);
                return;
            }

            setActiveDocument(item);
            setLoadingDocument(true);
            const response = await fetch(`${ORIGIN}/document/cmmipc/download?fahPAIsfo=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    document: item,
                }),
            });

            if (!response.ok) {
                setActiveDocument({ ...item, error: true });
                setShowSavedNotification({
                    show: true,
                    title: 'Error',
                    desc: 'There was an error fetching the document. Please refresh to try again.',
                    status: 'error',
                });
                setLoadingDocument(false);
                return;
            }

            if (response.status === 204) {
                setActiveDocument({ ...item, empty: true });
                setLoadingDocument(false);
                return;
            }

            const data = await response.json();

            addPdfDataToDocument(item, selectedGroupName, data);
            setActiveDocument(data);
        } catch (error) {
            logError(error, 'CMM+IPC setActive');
            setShowSavedNotification({
                show: true,
                title: 'Error',
                desc: 'There was an error fetching the document. Please refresh to try again.',
                status: 'error',
            });
        } finally {
            setLoadingDocument(false);
        }
    };

    async function handleFetchDocuments(route, documentData, setDocumentData, isPrimary) {
        let error = false;
        let updatedDocumentData;
        const partNumber = queryParamsObject?.partNumber;
        const id = queryParamsObject?.id;

        try {
            const response = await fetch(route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    partNumber,
                    id,
                }),
            });

            if (!response || !response.ok) {
                setDocumentData({ ...documentData, documents: [] });
                return;
            }

            if (response.status === 204) {
                setActiveDocument({ empty: true });
                return;
            }

            const data = await response.json();

            const updatedDocuments = data?.documentList?.map((doc) =>
                doc.documentId === data?.firstDocumentData?.documentId ? { ...doc, ...data.firstDocumentData } : doc
            );

            if (isPrimary) setActiveDocument(data.firstDocumentData ?? null);
            updatedDocumentData = updatedDocuments ?? [];
        } catch (error) {
            error = true;
            if (isPrimary) setActiveDocument({ error: true });
            logError(error, 'CMM+IPC fetchPacAirDocuments');
        } finally {
            setDocumentData({ ...documentData, documents: updatedDocumentData, error: error, loading: false });
        }
    }

    async function fetchPageData() {
        try {
            setLoadingDocument(true);
            await validateRequestParamters();
            const promises = groupDocumentData.map((group) => {
                return handleFetchDocuments(group.route, group.documentData, group.setDocumentData, group.isPrimary);
            });

            await Promise.allSettled(promises);
        } catch (error) {
            // Set all groups loading state to false
            groupDocumentData.forEach((group) => {
                group.setDocumentData({ ...group.documentData, documents: [], error: true, loading: false });
            });
            setLoadingDocument(false);
            logError(error, 'CMM+IPC fetchPageData');
            setShowSavedNotification({
                show: true,
                title: 'Error',
                desc: 'There was an error fetching the documents. Please refresh to try again.',
                status: 'error',
            });
        } finally {
            setLoadingDocument(false);
        }
    }

    // Validate authorization before grabbing CMM+IPC files
    async function validateRequestParamters() {
        const fetchRequestbody = {
            partNumber: queryParamsObject?.partNumber,
            id: queryParamsObject?.id,
        };

        const response = await fetch(`${ORIGIN}/document/cmmipc/validate-part?fahPAIsfo=${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fetchRequestbody),
        });

        if (response.status === 500)
            throw Error(`No part found for ${queryParamsObject?.partNumber} Id: ${queryParamsObject?.id}`);
    }

    const handleCloseNotification = () => {
        setShowSavedNotification({
            show: false,
            title: '',
            desc: '',
            status: '',
        });
    };

    useEffect(() => {
        fetchPageData();
    }, []);

    useEffect(() => {
        if (showSavedNotification.show) {
            setTimeout(handleCloseNotification, 5000);
        }
    }, [showSavedNotification.show]);

    const handleRowClick = (document) => {
        setTimeout(() => {
            document.element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    };

    return (
        <div className='flex flex-col min-h-screen'>
            <Notification
                showSavedNotification={showSavedNotification.show}
                setShowSavedNotification={handleCloseNotification}
                title={showSavedNotification.title}
                desc={showSavedNotification.desc}
                status={showSavedNotification.status}
            />
            <nav className='bg-blue-primary p-4 flex justify-center py-2'>
                <h1 className='text-white text-center text-2xl'>CMM+IPC</h1>
            </nav>
            <div className='flex flex-grow p-4'>
                <div className='w-[30%] border-gray-300'>
                    <h2 className='mb-6 text-2xl font-semibold text-center underline'>Table of Contents</h2>
                    <div className='h-full overflow-auto'>
                        <section className='flex flex-row'>
                            {groupDocumentData.map((group) => {
                                return (
                                    <button
                                        className={
                                            'flex bg-blue-primary w-fit px-5 py-1 rounded-t rounded-b-none !border-b text-white !border-gray-300 hover:!opacity-70 ' +
                                            (selectedDocumentGroup === group.name
                                                ? '!opacity-100 pointer-events-none'
                                                : '!opacity-50')
                                        }
                                        onClick={() => setSelectedDocumentGroup(group.name)}
                                        style={{
                                            border: (1, 'solid', '#d1d5db'),
                                        }}
                                    >
                                        {group.name} ({group.documentData?.documents?.length ?? 0})
                                    </button>
                                );
                            })}
                        </section>
                        <table className='w-full border'>
                            <thead className='sticky top-0 border bg-blue-primary border-blue-primary'>
                                <tr className='w-full'>
                                    <th className='w-full p-2 text-left text-white'>Document Name</th>
                                </tr>
                            </thead>
                            {groupDocumentData.map((group) => {
                                if (selectedDocumentGroup !== group.name) return null;
                                return (
                                    <tbody className={loadingDocument ? 'pointer-events-none' : ''}>
                                        {group?.documentData?.documents?.length ? (
                                            group?.documentData?.documents?.map((item, index) => (
                                                <DocumentRow
                                                    item={item}
                                                    index={index}
                                                    active={activeDocument?.documentId === item.documentId}
                                                    setActive={setActive}
                                                    handleRowClick={handleRowClick}
                                                    selectedDocumentGroup={selectedDocumentGroup}
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                {group.documentData?.loading ? (
                                                    <td className='p-2 text-center'>Loading Documents...</td>
                                                ) : (
                                                    <td className='p-2 text-center'>
                                                        No documents were found for this part number.
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                );
                            })}
                        </table>
                    </div>
                </div>

                <div className='w-[70%] pl-4 flex-grow flex items-center justify-center sticky left-[30%] top-[1%] h-[93vh]'>
                    <div className='flex flex-col items-center justify-center w-full h-full'>
                        <h2 className='mb-6 text-2xl font-semibold text-center'>
                            Part Number: {queryParamsObject?.partNumber}
                        </h2>
                        {activeDocument?.error ? (
                            <div className='relative flex items-center justify-center w-full h-full border-2 border-gray-300'>
                                <div className='text-center'>
                                    <div className='flex justify-center'>
                                        <img className='w-auto h-40' src={SimpleLogo} alt='Pacific Air Logo' />
                                    </div>
                                    <p className='text-base font-semibold text-indigo-600'>404</p>
                                    <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                                        Error loading document.
                                    </h1>
                                    <p className='mt-6 text-base leading-7 text-gray-600'>
                                        There was an error loading the document from this URL.
                                    </p>
                                </div>
                            </div>
                        ) : !loadingDocument ? (
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <div className='relative flex items-center justify-center w-full h-full border-2 border-gray-300'>
                                    {activeDocument?.empty ? (
                                        <p className='text-xl font-semibold text-center underline'>No document was found.</p>
                                    ) : (
                                        <>
                                            <p className='absolute text-xl font-semibold text-center'>
                                                Failed to fetch, or document is too large.
                                                <br />
                                                Please click the Dropbox link to the document to view it.
                                            </p>
                                            <embed
                                                src={`data:application/pdf;base64,${activeDocument?.pdf}`}
                                                type='application/pdf'
                                                className='relative z-10 w-full h-full'
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-col items-center justify-center w-full h-screen'>
                                <LoadingSpinner />
                                <p className='mt-20 ml-4 text-2xl font-semibold'>Loading Document...</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function DocumentRow({ item, index, active, setActive, handleRowClick, selectedDocumentGroup }) {
    return (
        <tr
            className={`flex flex-col items-center w-full cursor-pointer ${active ? 'border-2 border-gray-400' : ''}`}
            onClick={() => {
                setActive(item, selectedDocumentGroup);
                handleRowClick({ ...item, element: document.getElementById(`doc-row-${index}`) });
            }}
            id={`doc-row-${index}`}
        >
            <div
                key={index}
                className={`border-b ${index % 2 === 1 ? 'bg-gray-200' : ''} flex justify-between items-center w-full`}
            >
                <div className='p-2 ml-4'>
                    <a
                        href={item.documentLink ?? '#'}
                        className='text-blue-500 hover:underline'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {item.name}
                    </a>
                    <div className='text-sm text-gray-600'>{item.documentLocation}</div>
                </div>
                <div className='flex justify-between items-center mr-4 w-16'>
                    {item.pdf ? <CheckIcon className='w-5 h-5 text-green-500' /> : null}
                    <button className='p-1 rounded border'>
                        {active ? <ChevronDownIcon className='w-5 h-5' /> : <ChevronRightIcon className='w-5 h-5' />}
                    </button>
                </div>
            </div>
            {active ? (
                <div className='w-[95%] mb-2'>
                    <div className='w-full border'>
                        <div className='sticky top-0 bg-gray-200 border'>
                            <div className='flex justify-around font-bold'>
                                <h3 className='p-2 text-left'>Page #</h3>
                                <h3 className='p-2 text-right'>From Page</h3>
                            </div>
                        </div>
                        <div>
                            {item?.pages?.map((page, index) => (
                                <div className='flex justify-around'>
                                    <p className='p-2 text-left'>{index + 1}</p>
                                    <p className='p-2 text-right'>{page}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </tr>
    );
}
