// Utils
import config from '../../../../utils/config';
import logError from '../../../../utils/errorUtil';
import { keywordListToCheckAgainst } from './data';

// Variables
const ORIGIN = config.origin;

export async function getAvailableCameras(setCameras) {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        setCameras(videoDevices);
    } catch (err) {
        console.error('Failed to get cameras:', err);
    }
}

export const getReleaseLineData = async (currentUser, releaseNumber) => {
    const response = await fetch(`${ORIGIN}/wms/trace/get-release-line-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            releaseNumber,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        }),
    });

    if (!response.ok) {
        throw new Error(`Failed to get release line data: Error Code ${response.status} ${response.statusText}`);
    }

    return response.json();
};

export const getTraceDocuments = async (currentUser, currentInvLine) => {
    const response = await fetch(`${ORIGIN}/wms/trace/get-trace-documents`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            currentInvLine,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        }),
    });

    if (!response.ok) {
        throw new Error(`Failed to get trace documents: Error Code ${response.status} ${response.statusText}`);
    }

    return response.json();
};

export const printAllPickingLabels = async (currentUser, releaseNumber) => {
    try {
        let dataToSend = {
            releaseNumber: releaseNumber,
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
        };

        await fetch(`${ORIGIN}/wms/picking/get-picking-tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.blob())
            .then((blob) => {
                // open in a new tab
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                //name of the file
                a.name = 'order.pdf';
                a.click();
            });
    } catch (error) {
        logError(error, 'printAllPickingLabels');
    }
};

export const submitTracePhoto = async (photoBlob, isHero, authData, inventoryData, isAccepted, releaseId) => {
    try {
        const { invLineId, invLineName, ProductName, ownerCodeName } = inventoryData;
        const url = `${ORIGIN}/wms/trace/submit-trace-photo`;

        // Create the formData and append the necessary data
        const formData = new FormData();
        formData.append('authentication', JSON.stringify(authData));
        formData.append('isHero', isHero);
        formData.append('invLineId', invLineId);
        formData.append('invLineName', invLineName);
        formData.append('ProductName', ProductName);
        formData.append('ownerCodeName', ownerCodeName);
        formData.append('photo', photoBlob, 'photo.png');
        formData.append('isAccepted', isAccepted);
        formData.append('releaseId', releaseId);

        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) throw new Error('Error sending Trace photo upload to server.');

        const data = await response.json();

        return data;
    } catch (error) {
        logError(error, 'submitTracePhotos');
        return null;
    }
};

export const getInvPhotos = async (invLineName, dropboxUrl, currentUser) => {
    try {
        const response = await fetch(`${ORIGIN}/wms/trace/get-inv-photos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                invLine: invLineName,
                dirUrl: dropboxUrl,
                authentication: {
                    userId: currentUser.authentication.userId,
                    userEmail: currentUser.authentication.userEmail,
                    userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
                },
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to get inventory photos: Error Code ${response.status} ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        logError(error, 'getInvFiles');
    }
};

export const phySubStepFailed = (submissionStatus) => {
    Object.values(submissionStatus).some((step) => step === 'fail');
};

export const printImage = (imageUriFromBase64) => {
    // Create a hidden iframe to load the content for printing
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0';
    printFrame.style.height = '0';
    printFrame.style.border = 'none';

    document.body.appendChild(printFrame);

    // Create a document inside the iframe
    const doc = printFrame.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Print Image</title>
          <style>
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
          </style>
        </head>
        <body>
          <img id="imageToPrint" src="${imageUriFromBase64}" alt="Image to print" />
        </body>
      </html>
  `);
    doc.close();

    // Get the image element inside the iframe
    const image = doc.getElementById('imageToPrint');

    // Wait for the image to fully load before printing
    image.onload = () => {
        // Trigger the print function once the image is fully loaded
        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();

        // Remove the iframe after printing
        printFrame.onafterprint = () => {
            document.body.removeChild(printFrame);
        };
    };
};

export const sendChatterMessage = async (currentUser, currentInvLine, userInput) => {
    try {
        const dataToSend = {
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            messageData: {
                inventoryId: currentInvLine.invLineId,
                partId: currentInvLine.productId,
                keyword: currentInvLine.keyword,
                userName: currentUser.authentication.userName,
                userInput,
                relatedOrderId: currentInvLine.salesOrder || currentInvLine.repairOrder,
            },
        };

        const response = await fetch(`${ORIGIN}/wms/trace/submit-chatter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) throw new Error('Failed to upload chatter message');

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        logError(error, 'submitChatterMessage');
        return null;
    }
};

export const updateHeroPhotos = async (photosChanged, currentUser, invLine, isAccepted) => {
    try {
        const { invLineName, releaseName, ownerCodeName } = invLine;
        if (!photosChanged?.length) return { success: true };

        const url = `${ORIGIN}/wms/trace/submit-photos-changed`;

        // Remove the base64 data from the photos
        const photos = photosChanged.map((photo) => {
            return {
                name: photo.name,
                isHero: photo.isHero,
            };
        });

        const body = JSON.stringify({
            authentication: {
                userId: currentUser.authentication.userId,
                userEmail: currentUser.authentication.userEmail,
                userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
            },
            photos,
            invLineName,
            isAccepted,
            releaseName,
            ownerCodeName,
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        if (!response.ok) throw new Error('Failed to update the photos to be modified in Trace tab.');

        const data = response.json();
        return data;
    } catch (error) {
        logError(error, 'updatePhotos');
        return null;
    }
};

export const updateReleaseInspectionStatus = async (
    status,
    inspectionType,
    releaseLineName,
    releaseName,
    inspectionTime,
    currentUser
) => {
    const url = `${ORIGIN}/wms/trace/update-inspection-status`;

    const body = JSON.stringify({
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        releaseLineName,
        releaseName,
        status: { [inspectionType]: status },
        inspectionTime,
        inspectionType,
        userId: currentUser.authentication.userId,
    });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        const result = await response.json();

        return result;
    } catch (error) {
        logError(error, 'updateReleaseInspectionStatus');
    }
};

export const updateReleaseAndOrReleaseLineStatus = async (currentUser, releaseId, releaseLineId, releaseLineStatus) => {
    const url = `${ORIGIN}/wms/trace/update-release-and-release-line-status`;

    const body = JSON.stringify({
        authentication: {
            userId: currentUser.authentication.userId,
            userEmail: currentUser.authentication.userEmail,
            userCompany: currentUser.authentication.userCompany || localStorage.getItem('company'),
        },
        releaseId,
        releaseLineId,
        releaseLineStatus,
    });

    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });
    } catch (error) {
        logError(error, 'updatePhysicalReleaseStatus');
    }
};

export const getDefaultInspectionRequirements = (releaseLine) => {
    let requirments = [
        {
            requirement: 'Inspect the part for damage and verify against condition code',
            value: releaseLine.conditionCodeName,
            pass: false,
        },
        {
            requirement: 'Verify part number against order',
            value: releaseLine.partName,
            pass: false,
        },
        {
            requirement: 'Verify serial number against order',
            value: releaseLine.serialNumber,
            pass: false,
        },
        {
            requirement: 'Verify quantity',
            value: releaseLine.quantity,
            pass: false,
        },
    ];

    if (releaseLine.keyword && releaseLine.keyword !== '') {
        requirments.push({
            requirement: `Does the part match the appearance of ${releaseLine.keyword}`,
            value: 'Yes',
            pass: false,
        });
    }

    // Remove special characters and spaces and turn string to all lowercase
    const sanitizedKeyword = releaseLine?.keyword?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() ?? '';

    if (keywordListToCheckAgainst.includes(sanitizedKeyword)) {
        requirments.push({
            requirement: `Is the part (${releaseLine.keyword}) expired? Check against the packaging first, then Airbus and BOEING if Shelf Life (S/L) is not found on the packaging`,
            value: 'Yes',
            pass: false,
        });
    }

    if (releaseLine.expirationDate) {
        requirments.push({
            requirement: 'Verify expiration date',
            value: releaseLine.expirationDate,
            pass: false,
        });
    }

    if (releaseLine.isConnector) {
        requirments.push({
            requirement: 'Has correct number of pins',
            value: 'Yes',
            pass: false,
        });
    }

    if (releaseLine.darRequired && releaseLine.oemLabel) {
        requirments.push({
            requirement: 'OEM Label',
            value: 'Present',
            pass: false,
        });
    }

    if (releaseLine.darRequired && releaseLine.oemPackaging) {
        requirments.push({
            requirement: 'OEM Packaging',
            value: 'Present',
            pass: false,
        });
    }

    return requirments;
};
